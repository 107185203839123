/* You can add global styles to this file, and also import other style files */
@import 'config';
@import 'lightgallery/scss/lightgallery';

html,
body {
    height: 100%;
}

html {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    // min-width: 635px;
}

html::-webkit-scrollbar {
    //Chrome
    display: none;
}

body {
    margin: 0;
    font-family: sans-serif;
}

button:focus {
    outline: none;
}

.cursor-pointer {
    cursor: pointer;
}

.text-larger {
    font-size: 1.2rem;
}

// Override chrome autocomplet style
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    // -webkit-text-fill-color: $color-text-light;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:read-only,
textarea:-webkit-autofill:read-only,
select:-webkit-autofill:read-only {
    // -webkit-text-fill-color: $color-text-light-disabled;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent;
}
// Override autocomplet style // end
.mat-mdc-card {
    box-shadow: none !important;
}
.mat-mdc-text-field-wrapper {
    background-color: white !important;
}
.mdc-card {
    width: 100%;
}
.mat-mdc-raised-button .mdc-button__label {
    display: flex;
    align-items: center;
}
.mdc-button .mdc-button__label {
    display: flex !important;
    align-items: center !important;
}

.row.mx-0 {
    display: flex;
    justify-content: space-between;
} 
::ng-deep .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: transparent !important;
}
.mat-mdc-form-field-subscript-wrapper, .mat-mdc-form-field-bottom-align::before {
    display: none !important;
}

@media(max-width: 600px) {
    .mat-mdc-card-title, .mat-mdc-card-subtitle {
        padding: 0 !important;
    }
    .wrapper-accept-rule {
        margin-top: 20px !important;
    }
}
::ng-deep .mat-content.mat-content-hide-toggle {
    margin-right: 0 !important;
}
::ng-deep .mat-mdc-card-header {
    padding: 0 !important;
}