$color-primary: #008781;
$color-text-navbar: #ffffff;

$color-text-light: #f1f5f8;
$color-text-dark: #171e23;

$color-btn-primary: #008781;
$color-btn-primary-invert: #ffffff;
$color-btn-primary-text: #ffffff;
$color-bg-dark: #3c3c3c80;
$color-bg-dark-transparent: transparentize($color-bg-dark, 0.1);
$color-text-footer: #ffffff;
$color-footer: #008781;
$color-border: #3d4d4c29;

@import '../../common/styles';

html {
    --mat-datepicker-calendar-date-selected-state-background-color: #008781;
}
html .mat-mdc-mini-fab.mat-accent {
    --mdc-fab-small-container-color: #c5c5c5;
}

// progress bar
:host ::ng-deep .activeStep {
    background-color: $color-primary !important;
}
//

:host ::ng-deep .mat-expansion-panel-header {
    padding: 0rem;
    margin-bottom: 1rem;

    .mat-card-conetnt {
        max-height: 100%;
    }

    .mat-card {
        width: 100%;
        border: 0px;
        padding: 0;
        box-shadow: none;
        margin-bottom: 0px;
    }
}

:host ::ng-deep .container-image {
    width: auto !important;
}
.cc-window {
    background-color: #e6e6e6 !important;
    color: #393737 !important;
    font-size: 12px !important;
    line-height: 12px !important;
    padding: 1rem !important;
}
.cc-theme-classic .cc-btn:last-child {
    color: #000;
}
.guest-info {
    margin: 0 !important;
}

.nights,
.breakfast {
    display: flex;
    align-items: center;
    padding: 4px;
    border: 1px solid $color-border;
    border-radius: 10px;
    margin-right: 5px;
    font-size: 20px;
    font-weight: 500;

    .material-icons {
        margin-right: 5px !important;
    }
}

.header {
    background-color: $color-primary;
}

::ng-deep .mat-checkbox-frame {
    border-color: $color-primary !important;
}

.formGroupCompany{
    padding: 0 !important;
    margin-bottom: 10px !important;
}

.header-image {
    display: block !important;
    filter: brightness(0) invert(1) !important;
    height: 200px !important;
    width: auto !important;
}

:host ng-deep .guest-form {
    max-width: 800px !important;
}

:host ::ng-deep mat-card.mat-card.mat-focus-indicator.mat-card__offer {
    padding: 0;
    margin-bottom: 20px;
    box-shadow: none;
}

:host ::ng-deep .groups {
    margin-left: 10px;
    font-size: 30px;
}

:host ::ng-deep .mat-card-subtitle {
    margin: 0 !important;
}

::ng-deep .img-offer {
    border-radius: 8px;
}
::ng-deep .home {
    height: 100px !important;
    background: $color-btn-primary;
}
::ng-deep .brand-name {
    background-color: transparent !important;
}
::ng-deep .reservationBackground,
.details {
    position: absolute;
    background-color: #00878129 !important;
    bottom: 0;
}
::ng-deep button.mat-focus-indicator.mat-icon-button.mat-button-base {
    color: #008781 !important;
}
// ikony
:host ::ng-deep .material-icons,
:host ::ng-deep .material-symbols-outlined,
:host ::ng-deep mat-icon {
    color: $color-primary !important;
}
//
::ng-deep .rest {
    margin-top: 20px;
}

::ng-deep .detail_offer,
::ng-deep .rest {
    font-size: 14px;
    background-color: #0087813b !important;
    color: #2f2f2f !important;
    border: 1px solid $color-border;
    height: 36px;
    line-height: 20px;
    font-weight: 600;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

h4.offer-title {
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
}
.container-inclusions,
.container-cancellation,
.container-modification,
.container-prepayment,
.container-breakfast,
.container-children,
.container-pets,
.container-checkin {
    width: 100%;
    padding: 0 20px;
    text-align: justify;
    line-height: 24px;
}

.offer-list-popup {
    padding: 0;
}

:host ::ng-deep .loader {
    border-top: 16px solid $color-btn-primary !important;
}
:host ::ng-deep .mat-expansion-panel {
    padding-top: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important ;
    margin-bottom: 10px !important;
    border-radius: 5px !important;
    border: 1px solid $color-border !important;
}
::ng-deep .mat-horizontal-stepper-header-container {
    display: none !important;
}
:host ::ng-deep .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: 0px 3px 1px -2px #00878130, 0px 2px 2px 0px #0087810f, 0px 1px 5px 0px #00878129 !important;
}
::ng-deep .main,
.person-configuration__menu {
    font-weight: 500 !important;
}

:host ::ng-deep .container-search {
    margin-bottom: 0 !important;
    background-color: white;
}

:host ::ng-deep .mat-expansion-panel-body {
    padding: 0 !important;
}

:host ::ng-deep .mat-form-field {
    background: white !important;
}

:host ::ng-deep button.mat-focus-indicator.mat-menu-trigger.person-configuration__menu.person-configuration__menu-button.mat-button.mat-button-base {
    background: white !important;
    font-weight: 500 !important;
    height: 70px !important;
    font-size: 15px !important;
}
:host ::ng-deep .d-flex.new-res__checkbox.main {
    height: 70px !important;
}

:host ::ng-deep .btn-voucher {
    background-color: $color-btn-primary;
}

::ng-deep .mat-raised-button.mat-primary {
    border-radius: 0px !important;
    background: #252928 !important;
}

:host ::ng-deep .reservationSummary {
    background-color: transparent !important;
    color: black !important;
    padding: 20px !important;
    border-radius: 10px !important;
    border: 1px solid $color-border;
}
:host ::ng-deep .gallery-btn {
    width: 100% !important;
}

// label śniadania

.mat-checkbox-layout .mat-checkbox-label,
.adultNumberControl {
    color: #6e6e6e !important;
}
//

:host ::ng-deep .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    display: flex !important;
    align-items: center !important;
}
.mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
    margin-bottom: 20px;
}
.btn-online {
    min-width: 160px !important;
}
.room-title, .nights, .breakfast {
    color: black;
}
.room-title {
    margin: 0 !important;
    font-size: 26px !important;
}
.configuration-person {
    display: flex;
    align-items: center;
    height: 50px;
    margin: 10px 0;
}

::ng-deep .mat-mdc-raised-button {
    background-color: #252928 !important;
}
::ng-deep span.mdc-button__label {
    display: flex;
    min-width: 140px;
    justify-content: center;
}

// poprawa do mobilnych

@media (max-width: 800px) and (orientation: portrait) {
    button.mat-focus-indicator.mat-menu-trigger.person-configuration__menu.person-configuration__menu-button.mat-button.mat-button-base {
        margin: 0 !important;
    }
    ::ng-deep .mat-horizontal-stepper-header-container {
        display: none !important;
    }
    .mat-datepicker-popup {
        position: inherit !important;
    }
    .cdk-overlay-pane {
        display: unset !important;
    }

    mat-card-title.mat-card-title.text-center.text-lg-left.my-2.my-lg-0.mx-3 {
        padding: 0 !important;
        margin: 0 !important;
        height: 40px;
        display: flex;
        align-items: center;
    }
    .wrapper-additions {
        flex-direction: column !important;
    }

    .formGroupCompany{
        padding: 0 !important;
    }
    .mat-expansion-panel-body {
        padding-right: 10px !important;
        padding-left: 0px !important;
        padding-bottom: 0px !important;
        padding-top: 0 !important;
    }
    #reservation-form__guest--back,
    #reservation-form__guest--next {
        margin: 0 !important;
    }
    .mat-expansion-panel-header {
        margin-right: 10px !important;
    }

    img.confirm-img.d-flex.justify-content-center {
        width: auto !important;
        margin-top: 20px;
    }
    .hidden-mobile {
        display: none;
    }

    .nights,
    .breakfast {
        padding: 30px 10px;

        .mat-icon {
            margin-right: 5px;
        }
    }

    .container-icon {
        margin-left: 0 !important;
    }
    .reservationWrapper {
        margin-left: 0 !important;
    }
    ::ng-deep .myReservationWidth {
        padding: 0 !important;
    }
}

@media (min-width: 1200px) {
    :host ::ng-deep {
        .container-search .mat-form-field-appearance-outline .mat-form-field-outline {
            display: none !important;
        }
        .main,
        .person-configuration__menu {
            border: 3px solid #54545438 !important;
        }
    }
}

@media (max-width: 1150px) {
    :host ::ng-deep {
        .room-title {
            font-size: 28px !important;
            line-height: 30px !important;
        }
        .roomCardTitle {
            align-items: unset !important;
        }
        .container-icon {
            margin-left: 10px !important;
        }
        .rest {
            margin-bottom: 10px !important;
        }
        .form-group {
            border: 1px solid #e6dbdb !important;
        }
        .person-configuration__menu-button {
            height: 56px !important;
        }
    }
}

// skopiować do innych configów inputy na belce
@media (min-width: 1150px) {
    ::ng-deep .wrapper-inputs {
        position: absolute;
        top: 260px;
        margin: 0 auto;
        display: flex;
        width: 100%;
        background: transparent !important;
        height: initial !important;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        justify-content: center;
    }
    ::ng-deep .main,
    .person-configuration__menu {
        display: flex !important;
        align-items: center;
        top: 0 !important;
        font-weight: 500;
        font-size: 15px;
        height: 70px;
    }
}

:host ::ng-deep .paymentPanel {
    header {
        border: 1px solid $color-border;
        border-radius: 8px;
        padding: 10px;
    }

    .mat-icon {
        color: #80bdac;
    }

    &__icon {
        span {
            background-color: #ffffff66;
            border-radius: 0px;
            width: 30px;
            height: 30px;
        }
    }

    img.confirm-img {
        margin-top: 30px;
        max-width: 100%;
        width: 100%;
        &--small {
            max-width: 100px;
        }
    }
}

.myReservationWidth {
    padding: 0 20px !important;
}
